* {
  margin: 0;
  padding: 0;
  /* font-family: 'Manrope', sans-serif; */
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100% !important;
  /* font-family: 'Manrope', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  width: -moz-available;
  width: -webkit-fill-available;
}

.main-section {
  padding: 0 55px;
  display: block;
}
header {
  max-width: 100%;
  padding-left: 35px;
}
.logo {
  background-color: #1b2226;
  width: 120px;
  height: 155px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  text-align: center;
  padding-top: 80px;
}

.img {
  width: 69px;
  height: 40px;
}
img {
  border-style: none;
  vertical-align: middle;
  position: relative;
}

.hero_image {
  /* max-width: 80%;
  height: auto; */
}
.applyNow {
  display: block;
  outline: 0;
  cursor: pointer;
  border: none;
  padding: 5px 45px;
  height: 58px;
  border-radius: 15px;
  background-color: #0093f7;
  color: white;
  color: #ffffff;
  font-weight: 800;
  font-size: 18px;
}

.bottom_btn {
  display: block;
  outline: 0;
  cursor: pointer;
  border: none;
  padding: 5px 45px;
  height: 58px;
  border-radius: 15px;
  background-color: #0093f7;
  color: white;
  color: #ffffff;
  font-weight: 800;
  font-size: 18px;
}
.hero-section {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}
.introducing_one_card {
  padding-top: 70px;
  max-width: 50%;
  padding-left: 45px;
}
.OneCard_image {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.OneCard_heading h1 {
  font-size: 67px;
  line-height: 75px;
  color: #ffffff;
  text-align: left;
}
.OneCard_heading h1 span {
  color: #1893f7;
  text-align: left;
}
.flauntMetal {
  color: #ffffff;
  font-size: 35px;
  line-height: 80px;
  text-align: left;
}
.card-info {
  color: #ffffff;
  font-size: 18px;
  line-height: 36px;
  text-align: left;
  width: 470px;
}
.OneCard_apply {
  padding: 45px 0;
}
.fees-section {
  width: 100%;
  padding: 45px 0;
}
.Fees_Text_Section {
  text-align: center;
  padding: 0 15px;
}
.No_Hidden_Fees {
  font-size: 72px;
  background-color: red;
  background-image: linear-gradient(
    to right,
    #217ee6 0%,
    #3187d5 18%,
    #69a798 60%,
    #fcf2a6 97%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 105px;
  text-align: center !important;
  position: relative;
}
.Fees_Sub_Text {
  padding: 20px 0;
  font-size: 45px;
  line-height: 1.58;
  color: #ffffff;
  text-align: center !important;
}
.Bottom_Navbar {
  max-width: 100%;
  height: 80px;
  padding: 35px 0;
}
.Bottom_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: #0d1519;
  padding-right: 55px;
}
.Bottom_Nav_Image {
  max-width: 50%;
  padding: 0 35px;
}
.bottom_img {
  align-self: flex-start;
  max-width: 100%;
}
.Bottom_Nav_button {
  padding-right: 55px;
  max-width: 50%;
}
.Banking-Partners {
  padding: 45px 0;
  margin: auto;
  max-width: 100%;
}
.Banking-partners-image {
  text-align: center;
}
.Banking-partners-image img {
  max-width: 100%;
  height: auto;
}
.suggest-section {
  padding: 45px 0;
  margin: auto;
  max-width: 100%;
}
.suggest-div {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.suggest-img {
  margin: 10px 0;
}
.suggest-img img {
  max-width: 100%;
  height: auto;
}
.suggest-heading {
  color: #0093f7;
  text-align: center;
  font-size: 25px;
  padding: 15px 0;
}
.suggest-subheading {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 1.36;
}
.EMI-option-section {
  max-width: 100%;
  padding-right: 65px;
  padding-top: 55px;
  padding-bottom: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.EMI_text {
  max-width: 50%;
}
.EMI_heading {
  padding: 25px 0;
}
.EMI_heading h3 {
  color: #ffffff;
  font-size: 55px;
  line-height: 65px;
  text-align: right;
}
.EMI_subText {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding-left: 45px;
}
.EMI_subText p {
  color: #ffffff;
  font-size: 23px;
  line-height: 48px;
  text-align: right;
}
.reward-section {
  max-width: 100%;
  padding-left: 65px;
  padding-top: 55px;
  padding-bottom: 55px;
  display: flex;
  justify-content: space-between;
}
.reward_image {
  text-align: left;
}
.reward_image img {
  width: 49px;
  height: auto;
}
.icon_image {
  text-align: right;
}
.icon_image img {
  width: 49px;
  height: auto;
}
.reward-div {
  max-width: 50%;
}
.reward_heading {
  padding: 20px 0;
}
.reward_heading h3 {
  color: #ffffff;
  font-size: 52px;
  line-height: 60px;
  text-align: left;
}
.reward_subText {
  text-align: left;
  padding-right: 35px;
}
.reward_subText p {
  color: #ffffff;
  font-size: 23px;
  line-height: 48px;
  text-align: left;
}
.inner_div_reward_subText {
  width: 100%;
}
.metal-section {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 55px 0;
  align-items: center;
}
.metal-div {
  max-width: 50%;
}
.metal_subText {
  text-align: left;
}
.metal_subText p {
  color: #ffffff;
  font-size: 23px;
  line-height: 45px;
  text-align: left;
}
.inner_div_metal_subText {
  max-width: 80%;
}
.metal_heading {
  padding: 20px 0;
}
.metal_heading h3 {
  color: #ffffff;
  font-size: 52px;
  line-height: 60px;
  text-align: left;
}
.Metal_image {
  max-width: 50%;
}
.metal_img {
  width: 350px;
  max-width: 100%;
}
.metal_image {
  width: 400px;
  max-width: 100%;
  height: auto;
}
.seamless_onboarding_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 65px 45px;
}
.mock-up-img-class {
  text-align: center;
  margin: auto;
  max-width: 50%;
}
.seamless_onboarding_image {
  width: 450px;
  height: auto;
}
.section-text {
  max-width: 50%;
}
.onboarding-text {
  max-width: 50%;
  text-align: right;
}
.section_heading {
  padding: 15px 0;
}
.section_heading h3 {
  color: #ffffff;
  font-size: 52px;
  line-height: 55px;
  text-align: right;
}

.inner_div_subText {
  padding-left: 65px;
}
.inner_div_subText p {
  color: #ffffff;
  font-size: 23px;
  line-height: 42px;
  text-align: right;
}
.CopyRights {
  max-width: 100%;
  text-align: center;
  padding: 20px 0;
  margin-top: 20px;
  margin-bottom: 100px;
}
.CopyRights p {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: normal;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .main-section {
    padding: 0 55px;
  }
  header {
    max-width: 100%;
    padding-left: 45px;
  }
  .logo {
    background-color: #1b2226;
    width: 110px;
    height: 140px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    text-align: center;
    padding-top: 70px;
  }

  .img {
    width: 59px;
    height: auto;
  }
  .hero-section {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
  }
  .introducing_one_card {
    padding-top: 70px;
    max-width: 50%;
    padding-left: 35px;
  }
  .OneCard_image {
    max-width: 50%;
    text-align: center;
  }
  .hero_image {
    max-width: 80%;
  }
  .OneCard_heading h1 {
    font-size: 70px;
    line-height: 85px;
    color: #ffffff;
  }
  .applyNow {
    display: block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 5px 19px;
    height: 58px;
    border-radius: 15px;
    background-color: #0093f7;
    color: white;
    color: #ffffff;
    font-weight: 800;
    font-size: 18px;
  }
  .card-info {
    display: inline-block;
    color: #ffffff;
    font-size: 18px;
    line-height: 36px;
    text-align: left;
    max-width: 100%;
  }
  .section-text {
    max-width: 100%;
  }
  .Bottom_Navbar {
    max-width: 100%;
  }
  .Bottom_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: #0d1519;
    padding-right: 55px;
    padding: 35px 55px 15px 0;
  }
  .Bottom_Nav_Image {
    max-width: 50%;
    padding: 0 35px;
  }
  .bottom_img {
    align-self: flex-start;
    max-width: 100%;
  }
  .Bottom_Nav_button {
    padding-right: 0;
    max-width: 50%;
  }
  .bottom_btn {
    display: block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 5px 30px;
    width: 100%;
    height: 58px;
    border-radius: 15px;
    background-color: #0093f7;
    color: white;
    color: #ffffff;
    font-weight: 800;
    font-size: 18px;
  }

  .EMI-option-section {
    max-width: 100%;
    padding-top: 55px;
    padding-bottom: 55px;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mock-up-img-class {
    max-width: 50%;
  }
  .EMI_heading h3 {
    color: #ffffff;
    font-size: 48px;
    line-height: 63px;
    text-align: right;
  }
  .EMI_subText {
    padding-left: 0;
  }
  .EMI_subText p {
    color: #ffffff;
    font-size: 20px;
    line-height: 45px;
    text-align: right;
  }
  .EMI_text {
    max-width: 50%;
    padding-left: 25px;
  }

  .reward-section {
    max-width: 100%;
    padding-left: 0;
    padding-top: 55px;
    padding-bottom: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reward-div {
    max-width: 50%;
    padding-right: 25px;
  }
  .reward_heading h3 {
    color: #ffffff;
    font-size: 42px;
    line-height: 55px;
    text-align: left;
  }
  .reward_subText p {
    color: #ffffff;
    font-size: 20px;
    line-height: 45px;
    text-align: left;
  }
  .metal-section {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 55px 0;
    align-items: center;
  }
  .metal-div {
    max-width: 50%;
  }
  .metal_subText {
    text-align: left;
  }
  .Metal_image {
    max-width: 50%;
    padding-top: 35px;
  }
  .seamless_onboarding_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 55px 0;
    max-width: 100%;
  }
  .seamless_onboarding_image {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .mock-up-img-class {
    text-align: center;
    margin: auto;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }
  .onboarding-text {
    max-width: 50%;
    text-align: right;
  }

  .section_heading {
    padding: 15px 0;
  }
  .section_heading h3 {
    color: #ffffff;
    font-size: 45px;
    line-height: 55px;
    text-align: right;
  }

  .inner_div_subText {
    padding-left: 0;
  }
  .inner_div_subText p {
    color: #ffffff;
    font-size: 20px;
    line-height: 40px;
    text-align: right;
  }
}

@media only screen and (min-width: 481px) and (max-width: 640px) {
  .CopyRights {
    margin-bottom: 0px;
  }
  .Banking-Partners {
    padding: 10px 0;
  }
  .suggest-subheading {
    display: none;
  }
  .flauntMetal {
    display: none;
  }
  .OneCard_heading {
    display: none;
  }
  .main-section {
    padding: 0 55px;
  }
  header {
    max-width: 100%;
    padding-left: 45px;
  }
  .logo {
    background-color: #1b2226;
    width: 110px;
    height: 140px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    text-align: center;
    padding-top: 70px;
  }

  .img {
    width: 59px;
    height: auto;
  }
  .hero-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
  }
  .introducing_one_card {
    padding-top: 10px;
    max-width: 100%;
    padding-left: 0px;
  }
  .OneCard_image {
    max-width: 100%;
  }
  .hero_image {
    padding: 0;
    animation: moveupdown 2s ease-in Infinite alternate 0.3s;
  }
  .OneCard_heading h1 {
    font-size: 65px;
    line-height: 85px;
    color: #ffffff;
  }
  .card-info {
    display: none;
    color: #ffffff;
    font-size: 18px;
    line-height: 36px;
    text-align: left;
    max-width: 100%;
  }
  .Bottom_Navbar {
    max-width: 100%;
  }
  .Bottom_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: #0d1519;
    padding: 25px 25px 0 25px;
  }
  .Bottom_Nav_Image {
    display: none;
  }

  .Bottom_Nav_button {
    padding-right: 0;
    max-width: 100%;
    padding-bottom: 25px;
    padding-top: 30px;
  }
  .bottom_btn {
    display: block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 5px 45px;
    width: 100%;
    height: 58px;
    border-radius: 15px;
    background-color: #0093f7;
    color: white;
    color: #ffffff;
    font-weight: 800;
    font-size: 18px;
  }

  .EMI-option-section {
    max-width: 100%;
    padding-top: 55px;
    padding-bottom: 55px;
    padding-right: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .EMI_heading h3 {
    color: #ffffff;
    font-size: 48px;
    line-height: 60px;
    text-align: right;
  }
  .EMI_text {
    max-width: 100%;
  }
  .EMI_subText {
    padding-left: 0;
  }
  .EMI_subText p {
    color: #ffffff;
    font-size: 19px;
    line-height: 43px;
    text-align: right;
  }
  .inner_div_EMI_subText {
    width: 100%;
  }
  .reward-section {
    max-width: 100%;
    padding-left: 25px;
    padding-top: 0;
    padding-bottom: 55px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .reward-div {
    max-width: 100%;
    padding-bottom: 45px;
  }
  .reward_subText {
    max-width: 100%;
    padding: 0;
  }
  .reward_heading h3 {
    color: #ffffff;
    font-size: 48px;
    line-height: 55px;
    text-align: left;
  }

  .reward_subText p {
    color: #ffffff;
    font-size: 19px;
    line-height: 45px;
    text-align: left;
  }
  .inner_div_reward_subText {
    width: 100%;
  }
  .metal-section {
    max-width: 100%;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 55px 0;
    padding-bottom: 0;
  }
  .metal-div {
    max-width: 100%;
    padding: 45px 0;
    padding-left: 25px;
  }
  .metal_subText {
    text-align: left;
  }
  .metal_subText p {
    color: #ffffff;
    font-size: 21px;
    line-height: 42px;
    text-align: left;
  }
  .inner_div_metal_subText {
    max-width: 90%;
  }

  .metal_heading {
    color: #ffffff;
    font-size: 55px;
    line-height: 60px;
    text-align: left;
    padding: 25px 0;
  }
  .Metal_image {
    max-width: 100%;
    padding: 0px 35px 25px 35px;
  }
  .metal_img {
    max-width: 100%;
  }
  .metal_image {
    width: 400px;
    max-width: 100%;
    height: auto;
  }
  .seamless_onboarding_section {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 55px 0;
    padding-top: 0;
  }
  .mock-up-img-class {
    text-align: center;
    margin: auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 0;
  }
  .seamless_onboarding_image {
    width: 450px;
    height: auto;
  }
  .onboarding-text {
    max-width: 100%;
    text-align: right;
    padding: 55px 0;
  }

  .section_heading {
    color: #ffffff;
    font-size: 45px;
    line-height: 70px;
    text-align: right;
    padding: 25px 0;
  }
  .section-text {
    max-width: 100%;
  }
  .inner_div_subText {
    padding-left: 65px;
  }
  .inner_div_subText p {
    color: #ffffff;
    font-size: 20px;
    line-height: 40px;
    text-align: right;
  }
  .fees-section {
    display: none;
  }
  .suggest-heading {
    display: none;
  }
  .suggest-section {
    padding: 10px 0;
  }
  .OneCard_apply {
    padding: 10px 0;
  }
  .suggest-img img {
    max-width: 70%;
  }
  .applyNow {
    animation: shake 1.5s ease-in alternate 3s;
  }
}

@media screen and (max-width: 480px) {
  .CopyRights {
    margin-bottom: 0px;
  }
  .Banking-Partners {
    padding: 10px 10px;
  }
  .suggest-subheading {
    display: none;
  }
  .flauntMetal {
    display: none;
  }
  .OneCard_heading {
    display: none;
  }
  .main-section {
    padding: 0 0px;
  }
  header {
    max-width: 100%;
    padding-left: 35px;
  }
  .logo {
    background-color: #1b2226;
    width: 110px;
    height: 140px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    text-align: center;
    padding-top: 70px;
  }

  .img {
    width: 59px;
    height: auto;
  }
  .hero-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
  }
  .introducing_one_card {
    padding-top: 10px;
    max-width: 100%;
    padding-left: 0px;
  }
  .applyNow {
    display: block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 5px 20px;
    height: 46px;
    border-radius: 5px;
    background-color: #0093f7;
    color: white;
    color: #ffffff;
    font-weight: 800;
    font-size: 16px;
    animation: shake 1.5s ease-in alternate 3s;
  }
  .OneCard_image {
    max-width: 100%;
  }
  .hero_image {
    padding: 0px;
    animation: moveupdown 2s ease-in Infinite alternate 0.3s;
  }
  .OneCard_heading {
    max-width: 100%;
  }
  .OneCard_heading h1 {
    font-size: 55px;
    line-height: 70px;
    color: #ffffff;
  }
  .flauntMetal {
    color: #ffffff;
    font-size: 30px;
    line-height: 65px;
    text-align: left;
  }
  .card-info {
    display: none;
    color: #ffffff;
    font-size: 15px;
    line-height: 28px;
    text-align: left;
    max-width: 100%;
  }

  .No_Hidden_Fees {
    font-size: 45px;
    background-color: red;
    background-image: linear-gradient(
      to right,
      #217ee6 0%,
      #3187d5 18%,
      #69a798 60%,
      #fcf2a6 97%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 56px;
    text-align: center !important;
    position: relative;
  }
  .Fees_Sub_Text {
    padding: 20px 0;
    font-size: 35px;
    line-height: 1.65;
    color: #ffffff;
    text-align: center !important;
  }
  .Bottom_Navbar {
    max-width: 100%;
  }
  .Bottom_div {
    padding-right: 0;
  }
  .Bottom_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: #0d1519;
    padding: 25px 25px 0 25px;
  }
  .Bottom_Nav_Image {
    display: none;
  }
  .bottom_img {
    max-width: 100%;
  }
  .Bottom_Nav_button {
    padding-right: 0;
    max-width: 100%;
    padding-bottom: 25px;
    padding-top: 30px;
  }
  .bottom_btn {
    display: block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 5px 25px;
    width: 100%;
    height: 58px;
    border-radius: 15px;
    background-color: #0093f7;
    color: white;
    color: #ffffff;
    font-weight: 800;
    font-size: 16px;
  }
  .suggest-heading {
    color: #0093f7;
    text-align: center;
    font-size: 22px;
    padding: 15px 0;
  }
  .suggest-subheading {
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    line-height: 1.36;
  }
  .EMI-option-section {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 55px;
    padding-right: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .EMI_heading {
    padding: 15px 0;
  }
  .EMI_heading h3 {
    color: #ffffff;
    font-size: 40px;
    line-height: 55px;
    text-align: right;
  }
  .EMI_text {
    max-width: 100%;
    padding-right: 20px;
  }
  .EMI_subText {
    padding-left: 0;
  }
  .EMI_subText p {
    color: #ffffff;
    font-size: 17px;
    line-height: 42px;
    text-align: right;
  }

  .reward-section {
    max-width: 100%;
    padding-left: 25px;
    padding-top: 55px;
    padding-bottom: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .reward-div {
    max-width: 100%;
    padding-bottom: 0;
  }
  .reward_subText {
    max-width: 100%;
    padding: 0;
  }
  .reward_heading h3 {
    color: #ffffff;
    font-size: 38px;
    line-height: 52px;
    text-align: left;
  }

  .reward_subText p {
    color: #ffffff;
    font-size: 17px;
    line-height: 42px;
    text-align: left;
  }

  .reward_heading {
    display: flex;
    justify-content: flex-start;
    padding: 18px 0;
  }
  .metal-section {
    max-width: 100%;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    padding-bottom: 0;
  }
  .metal-div {
    max-width: 100%;
    padding: 45px 0;
    padding-left: 25px;
  }
  .metal_subText {
    text-align: left;
  }
  .metal_subText p {
    color: #ffffff;
    font-size: 17px;
    line-height: 40px;
    text-align: left;
  }
  .inner_div_metal_subText {
    max-width: 90%;
  }
  .metal_heading {
    padding: 15px 0;
  }
  .metal_heading h3 {
    color: #ffffff;
    font-size: 38px;
    line-height: 45px;
    text-align: left;
  }
  .Metal_image {
    max-width: 100%;
    padding: 0px 35px 25px 35px;
  }
  .metal_img {
    max-width: 100%;
  }
  .metal_image {
    width: 400px;
    max-width: 100%;
    height: auto;
  }

  .seamless_onboarding_section {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 55px 0;
    padding-top: 0;
  }
  .mock-up-img-class {
    text-align: center;
    margin: auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 0;
  }
  .onboarding-text {
    max-width: 100%;
    text-align: right;
    padding: 55px 0;
  }
  .section-text {
    max-width: 100%;
    padding-right: 20px;
  }
  .section_heading {
    padding: 15px 0;
  }
  .section_heading h3 {
    color: #ffffff;
    font-size: 38px;
    line-height: 50px;
    text-align: right;
  }

  .inner_div_subText {
    padding-left: 0;
  }
  .inner_div_subText p {
    color: #ffffff;
    font-size: 17px;
    line-height: 38px;
    text-align: right;
  }
  .fees-section {
    display: none;
  }
  .suggest-heading {
    display: none;
  }
  .suggest-section {
    padding: 10px 30px;
  }
  .OneCard_apply {
    padding: 10px 0;
  }
  .CopyRights {
    margin-bottom: 0px;
  }
  .suggest-img img {
    max-width: 60%;
  }
}

@media screen and (max-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
  .suggest-img img {
    max-width: 70%;
  }
  .CopyRights {
    margin-bottom: 0px;
  }
  .suggest-subheading {
    display: none;
  }
  .flauntMetal {
    display: none;
  }
  .OneCard_heading {
    display: none;
  }
  .main-section {
    padding: 0 0px;
  }
  header {
    max-width: 100%;
    padding-left: 0;
  }
  .logo {
    background-color: #1b2226;
    width: 100px;
    height: 130px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    text-align: center;
    padding-top: 60px;
  }

  .img {
    width: 59px;
    height: auto;
  }
  .hero-section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
  }
  .introducing_one_card {
    padding-top: 10px;
    max-width: 100%;
    padding-left: 0;
  }
  .OneCard_image {
    max-width: 100%;
  }
  .mock-up-img-class {
    padding: 0;
  }
  .hero_image {
    animation: moveupdown 2s ease-in Infinite alternate 0.3s;
  }
  .OneCard_heading h1 {
    font-size: 51px;
    line-height: 62px;
    color: #ffffff;
  }
  .card-info {
    display: none;
    color: #ffffff;
    font-size: 12px;
    line-height: 23px;
    text-align: left;
    max-width: 100%;
  }

  .applyNow {
    display: block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 5px 25px;
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background-color: #0093f7;
    color: white;
    color: #ffffff;
    font-weight: 800;
    font-size: 14px;
    animation: shake 1.5s ease-in alternate 3s;
  }
  .fees-section {
    padding-top: 25px;
    display: none;
  }
  .No_Hidden_Fees {
    font-size: 40px;
    background-color: red;
    background-image: linear-gradient(
      to right,
      #217ee6 0%,
      #3187d5 18%,
      #69a798 60%,
      #fcf2a6 97%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 50px;
    text-align: center !important;
    position: relative;
  }
  .Fees_Sub_Text {
    padding: 20px 0;
    font-size: 25px;
    line-height: 1.65;
    color: #ffffff;
    text-align: center !important;
  }
  .Bottom_Navbar {
    max-width: 100%;
  }
  .Bottom_div {
    padding-right: 0;
  }
  .Bottom_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: #0d1519;
    padding: 25px 25px 0 25px;
  }
  .Bottom_Nav_Image {
    display: none;
  }
  .bottom_img {
    max-width: 100%;
  }
  .Bottom_Nav_button {
    padding-right: 0;
    max-width: 100%;
    padding-bottom: 25px;
    padding-top: 30px;
  }
  .bottom_btn {
    display: block;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 5px 25px;
    width: 100%;
    height: 50px;
    border-radius: 15px;
    background-color: #0093f7;
    color: white;
    color: #ffffff;
    font-weight: 800;
    font-size: 14px;
  }

  .Banking-Partners {
    padding: 10px 0;
    text-align: center;
    max-width: 100%;
    padding: 0px 50px;
  }
  .Banking-Partners-image {
    text-align: center;
    max-width: 100%;
  }
  .suggest-heading {
    color: #0093f7;
    text-align: center;
    font-size: 22px;
    padding: 15px 0;
  }
  .suggest-subheading {
    color: #ffffff;
    font-size: 15px;
    text-align: center;
    line-height: 1.36;
  }
  .EMI-option-section {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 55px;
    padding-right: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .EMI_heading {
    padding: 20px 0px;
  }
  .EMI_heading h3 {
    color: #ffffff;
    font-size: 25px;
    line-height: 33px;
    text-align: right;
  }
  .EMI_text {
    max-width: 100%;
  }
  .EMI_subText {
    padding-left: 0;
  }
  .EMI_subText p {
    color: #ffffff;
    font-size: 15px;
    line-height: 35px;
    text-align: right;
  }

  .reward-section {
    max-width: 100%;
    padding-left: 15px;
    padding-top: 0;
    padding-bottom: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .reward-div {
    max-width: 100%;
    padding-bottom: 0;
    padding-right: 0;
  }
  .reward_subText {
    max-width: 100%;
    padding: 0;
  }
  .reward_heading h3 {
    color: #ffffff;
    font-size: 25px;
    line-height: 33px;
    text-align: left;
  }

  .reward_subText p {
    color: #ffffff;
    font-size: 15px;
    line-height: 33px;
    text-align: left;
  }

  .reward_heading {
    display: flex;
    justify-content: flex-start;
    padding: 18px 0;
  }
  .metal-section {
    max-width: 100%;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    padding-bottom: 0;
  }
  .metal-div {
    max-width: 100%;
    padding: 45px 0;
    padding-left: 15px;
  }
  .metal_subText {
    text-align: left;
  }
  .metal_subText p {
    color: #ffffff;
    font-size: 15px;
    line-height: 33px;
    text-align: left;
  }
  .inner_div_metal_subText {
    max-width: 100%;
  }
  .metal_heading {
    padding: 15px 0;
  }
  .metal_heading h3 {
    color: #ffffff;
    font-size: 25px;
    line-height: 33px;
    text-align: left;
  }
  .Metal_image {
    max-width: 100%;
    padding: 0px 35px 25px 35px;
  }
  .metal_img {
    max-width: 100%;
  }
  .metal_image {
    width: 400px;
    max-width: 100%;
    height: auto;
  }
  .seamless_onboarding_section {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 55px 0;
    padding-top: 0;
  }
  .mock-up-img-class {
    text-align: center;
    margin: auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 0 25px 0;
  }

  .onboarding-text {
    max-width: 100%;
    text-align: right;
    padding: 55px 0;
  }
  .section_heading {
    padding: 10px 0;
  }
  .section-text {
    max-width: 100%;
  }
  .section_heading h3 {
    color: #ffffff;
    font-size: 25px;
    line-height: 33px;
    text-align: right;
  }

  .inner_div_subText p {
    color: #ffffff;
    font-size: 15px;
    line-height: 35px;
    text-align: right;
  }
  .suggest-section {
    display: block;
    padding: 5px 50px;
  }
  .suggest-heading {
    display: none;
  }
  .OneCard_apply {
    padding: 10px 0;
  }
}

@keyframes moveupdown {
  100% {
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translate(20px);
    -ms-transform: translate(20px);
    -o-transform: translate(20px);
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-5px, 0, 0);
    -webkit-transform: translate3d(-5px, 0, 0);
    -moz-transform: translate3d(-5px, 0, 0);
    -ms-transform: translate3d(-5px, 0, 0);
    -o-transform: translate3d(-5px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(6px, 0, 0);
    -webkit-transform: translate3d(6px, 0, 0);
    -moz-transform: translate3d(6px, 0, 0);
    -ms-transform: translate3d(6px, 0, 0);
    -o-transform: translate3d(6px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
    -webkit-transform: translate3d(-6px, 0, 0);
    -moz-transform: translate3d(-6px, 0, 0);
    -ms-transform: translate3d(-6px, 0, 0);
    -o-transform: translate3d(-6px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(6px, 0, 0);
    -webkit-transform: translate3d(6px, 0, 0);
    -moz-transform: translate3d(6px, 0, 0);
    -ms-transform: translate3d(6px, 0, 0);
    -o-transform: translate3d(6px, 0, 0);
  }
}
